import { UserProfile } from 'types';
import { SalesCallout } from '~/components/product/callouts/sales-callout';

import styles from '~/styles/components/product/callouts.module.scss';

interface SalesCalloutProps {
  seller: UserProfile;
}

export default function SalesCallouts({ seller }: SalesCalloutProps) {
  function shouldRender() {
    return !!seller && Boolean(seller?.discounts?.length);
  }

  const salesDiscounts = seller?.discounts?.filter(d => ['value', 'percent'].includes(d.type));
  const freeShippingDiscounts = seller?.discounts?.filter(d => ['shipping'].includes(d.type));


  function renderSales() {
    return (
      <div className={styles['sale-callout-container']}>
        <SalesCallout
          discounts={salesDiscounts}
          type='sales'
        />
      </div>
    );
  }

  function renderShipping() {
    return (
      <div className={styles['sale-callout-container']}>
        <SalesCallout
          discounts={freeShippingDiscounts}
          type='shipping'
        />
      </div>
    );
  }

  function renderSalesCallouts() {
    return (
      <>
        {renderSales()}
        {renderShipping()}
      </>
    );
  }

  return shouldRender() ? renderSalesCallouts() : null;
}
