import { useCallback, useMemo } from 'react';
import Button, { ButtonProps } from '~/components/button';

import useFollow from '~/lib/hooks/use-follow';
import useAuthenticatedAction from '../lib/hooks/use-authenticated-action';
import SessionForm from './session-form';

import { useGlobalState } from '~/state';
import { ActionType } from '~/state/types';
import { UserProfile } from 'types';
import styles from '~/styles/components/follow-button.module.scss';

interface FollowButtonProps {
  seller: UserProfile;
  buttonProps?: ButtonProps;
}

const FollowButton = ({ seller, buttonProps }: FollowButtonProps) => {
  const { followUser, unfollowUser, isFollowing } = useFollow(seller);
  const { dispatch, state } = useGlobalState();
  const uid = useMemo(() => state?.user?.data?.uid, [state?.user?.data?.uid]);
  const sellerId = seller?.id || seller?.objectID;

  const { handleAuthAction } = useAuthenticatedAction({
    actionToCall: () => {
      if (isFollowing) {
        confirmUnfollow();
      } else {
        handleFollowClick();
      }
    },
  });

  const handleButtonClick = (e) => {
    e.preventDefault();
    handleAuthAction({
      title: 'Log in or sign up',
      message: 'To follow this seller, please log in to your account.',
      component: <SessionForm />,
    });
  };

  const handleFollowClick = useCallback(() => {
    followUser();
  }, [followUser]);

  const handleUnfollowClick = useCallback(
    (e) => {
      e.preventDefault();
      unfollowUser();
      dispatch({
        type: ActionType.FLUSH_MODAL_DATA,
      });
    },
    [unfollowUser, dispatch]
  );

  const confirmUnfollow = useCallback(() => {
    dispatch({
      type: ActionType.ADD_MODAL_DATA,
      payload: {
        title: 'Unfollow',
        message: `Unfollow ${seller.name}?`,
        component: (
          <div className={styles.seller}>
            <Button
              loading={!sellerId}
              size='small'
              text={'Confirm'}
              style='outline'
              onPress={handleUnfollowClick}
            />
          </div>
        ),
      },
    });
  }, [seller, handleUnfollowClick, dispatch]);

  if (!seller) {
    return null;
  }

  return (
    <Button
      disabled={sellerId === uid}
      loading={!sellerId}
      size={'small'}
      text={isFollowing ? 'Following' : 'Follow'}
      style={
        isFollowing ? 'outline' : buttonProps ? buttonProps.style : 'primary'
      }
      onPress={handleButtonClick}
      {...buttonProps}
    />
  );
};

export default FollowButton;
