import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { urlToTypesenseOptions } from 'sdk';

import { TypesenseSearchProps } from 'types';

interface UseRouterSearchOptionsProps {
  searchOptions?: TypesenseSearchProps;
}

export default function useRouterSearchOptions(props?: UseRouterSearchOptionsProps) {
  const router = useRouter();
  const [searchOptions, setSearchOptions] = useState<TypesenseSearchProps>(
    props?.searchOptions || urlToTypesenseOptions({ url: router.query as any })
  );

  useEffect(() => {
    setSearchOptions(props.searchOptions);
  }, [props.searchOptions]);

  useEffect(() => {
    if (!router.query.search) return;

    setSearchOptions(urlToTypesenseOptions({ url: router.query as any }));
  }, [router.query]);

  return searchOptions;
}
