import COLORS from '~/lib/helpers/color-helper';

interface Props {
  width?: number;
  height?: number;
  fillColor?: string;
}

export default function MosaicSVG({ width = 24, height = 24, fillColor = COLORS.primary }: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.3 2.40002H11V22.4H6.3C3.9 22.4 2 20.5 2 18.1V6.70002C2 4.30002 3.9 2.40002 6.3 2.40002Z'
        fill={fillColor}
      />
      <path
        d='M22 6.70002V11.4H13V2.40002H17.7C20.1 2.40002 22 4.30002 22 6.70002Z'
        fill={fillColor}
      />
      <path
        d='M13 13.4H22V18.1C22 20.4 20.1 22.4 17.7 22.4H13V13.4Z'
        fill={fillColor}
      />
    </svg>
  );
}
