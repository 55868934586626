import classNames from 'classnames';
import Link from 'next/link';

import Button, { ButtonProps } from './button';

import styles from '../styles/components/callout.module.scss';
import ChevronRightSVG from '../assets/svg/icons/chevron-right';
import COLORS from '~/lib/helpers/color-helper';
import Text from './typography/text';
import Card from './card';

export interface CalloutProps {
  style?: 'primary' | 'secondary' | 'tertiary' | 'green';
  small?: boolean;
  title?: string;
  message?: string;
  icon?: React.ReactElement;
  button?: ButtonProps;
  iconStyleOverrides?: string;
  containerStyles?: string;
  linkAction?: () => void;
  buttonIcon?: React.ReactElement;
  shadow?: boolean;
  inline?: boolean;
}

export default function Callout({ style = 'secondary', shadow = false, inline = false, ...props }: CalloutProps) {
  function renderCallout() {
    function renderButton() {
      if (props.small) return null;
      if (!props.button?.text) return null;
      if (!props.button?.link && !props.button?.onPress) return null;
      return (
        <>
          <div className={styles.buttonFullScreen}>
            <Button
              style={style === 'secondary' ? 'primary' : 'tertiary'}
              size={props.small ? 'xtra-small' : 'base'}
              {...props.button}
              link={undefined} // Remove link from button since it's already wrapped in a link
              icon={props.buttonIcon}
            />
          </div>
          <div className={styles.button}>
            <Text color={style === 'secondary' || style === 'tertiary' ? 'copyDark' : 'white'}>
              {props.button.text}
            </Text>
            <ChevronRightSVG
              strokeColor={style === 'secondary' || style === 'tertiary' ? COLORS.copyDark : COLORS.foreground}
            />
          </div>
        </>
      );
    }

    function renderSmallButton() {
      if (!props.small) return null;
      if (!props.button?.text) return null;
      if (!props.button?.link && !props.button?.onPress) return null;
      return (
        <div {...(props.button?.onPress && { onClick: props.button?.onPress })}>
          <div className={styles['small-button']}>
            <div>{props.button.text}</div>
            <div className={styles['chevron']}>
              <ChevronRightSVG />
            </div>
          </div>
        </div>
      );
    }

    return (
      <Card
        as='aside'
        noShadow={!shadow}
        className={classNames(styles.callout, {
          [styles['callout--primary']]: style === 'primary',
          [styles['callout--secondary']]: style === 'secondary',
          [styles['callout--tertiary']]: style === 'tertiary',
          [styles['callout--green']]: style === 'green',
          [styles['callout--small']]: props.small,
          [props.containerStyles]: Boolean(props.containerStyles),
        })}
      >
        {props.icon && (
          <span
            className={classNames(props.iconStyleOverrides || styles.icon, {
              [styles['icon--small']]: props.small,
            })}
          >
            {props.icon}
          </span>
        )}
        <div className={classNames(styles.content, { [styles['content--small']]: props.small })}>
          <div className={classNames({ [styles['content--inline']]: inline })}>
            {props.title && <div className={styles['content-title']}>{props.title}</div>}
            {props.message && <div className={styles['content-message']}>{props.message}</div>}
          </div>
          {renderSmallButton()}
          {renderButton()}
        </div>
      </Card>
    );
  }

  return props.button?.link ? (
    <Link {...props.button.link} onClick={props.linkAction}>
      {renderCallout()}
    </Link>
  ) : (
    renderCallout()
  );
}
