
interface LinkProps {
  title: string;
  text: string;
  url: string;
}

export const Share = {
  link: async (shareData: LinkProps) => {
    await navigator.share(shareData);
  },
};
