interface Props {
  fillColorMain?: string;
  fillColorSecondary?: string;
}

export default function TruckSVG({ fillColorMain = '#6AC9FE', fillColorSecondary = '#344997' }: Props) {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18.666 3.89268V14.9727C18.666 16.3327 17.5593 17.4393 16.1993 17.4393H3.99935C3.26602 17.4393 2.66602 16.8393 2.66602 16.106V7.58602C2.66602 4.86602 4.86602 2.66602 7.58602 2.66602H17.426C18.1193 2.66602 18.666 3.21268 18.666 3.89268Z'
        fill={fillColorSecondary}
      />
      <path
        d='M28.666 20.666C29.0393 20.666 29.3327 20.9593 29.3327 21.3327V22.666C29.3327 24.8793 27.546 26.666 25.3327 26.666C25.3327 24.466 23.5327 22.666 21.3327 22.666C19.1327 22.666 17.3327 24.466 17.3327 26.666H14.666C14.666 24.466 12.866 22.666 10.666 22.666C8.46602 22.666 6.66602 24.466 6.66602 26.666C4.45268 26.666 2.66602 24.8793 2.66602 22.666V19.9993C2.66602 19.266 3.26602 18.666 3.99935 18.666H16.666C18.506 18.666 19.9993 17.1727 19.9993 15.3327V7.99935C19.9993 7.26602 20.5993 6.66602 21.3327 6.66602H22.4527C23.4127 6.66602 24.2927 7.18602 24.7727 8.01268L25.626 9.50602C25.746 9.71935 25.586 9.99935 25.3327 9.99935C23.4927 9.99935 21.9993 11.4927 21.9993 13.3327V17.3327C21.9993 19.1727 23.4927 20.666 25.3327 20.666H28.666Z'
        fill={fillColorMain}
      />
      <path
        d='M10.6667 29.3333C12.1394 29.3333 13.3333 28.1394 13.3333 26.6667C13.3333 25.1939 12.1394 24 10.6667 24C9.19391 24 8 25.1939 8 26.6667C8 28.1394 9.19391 29.3333 10.6667 29.3333Z'
        fill={fillColorMain}
      />
      <path
        d='M21.3327 29.3333C2.8054 29.3333 23.9993 28.1394 23.9993 26.6667C23.9993 25.1939 22.8054 24 21.3327 24C19.8599 24 18.666 25.1939 18.666 26.6667C18.666 28.1394 19.8599 29.3333 21.3327 29.3333Z'
        fill={fillColorMain}
      />
      <path
        d='M29.3333 16.7067V18.6667H25.3333C24.6 18.6667 24 18.0667 24 17.3333V13.3333C24 12.6 24.6 12 25.3333 12H27.0533L28.9867 15.3867C29.2133 15.7867 29.3333 16.24 29.3333 16.7067Z'
        fill={fillColorMain}
      />
    </svg>
  );
}

