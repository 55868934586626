import { useEffect, useState } from 'react';
import type { GetStaticPropsContext, InferGetServerSidePropsType, NextPage } from 'next';
import Link from 'next/link';
import { where } from 'firebase/firestore';
import { log404 } from '~/lib/helpers/log404';

import {
  isFastShipper,
  isHighQualitySeller,
  possessive,
  lastActiveTime,
  mapQueryBy,
  searchWithFaceting,
  mapSortBy,
} from 'sdk';
import { Typesense } from 'sdk/src/clients/typesense/react-query';
import Firebase from '@/clients/firebase/firebase-react-query';
import { fetchCollectionDocsByQuery, getFollowData } from '~/clients/firebase/client';
import { FollowData } from '~/clients/types';
import { Book, UserProfile, TypesenseSearchProps } from 'types';

import useRouterSearchOptions from '~/lib/hooks/use-router-search-options';
import { Links } from '~/lib/helpers/link-helper';
import { Share } from '~/lib/helpers/share-helper';

import PageMetaData from '~/components/page-meta-data';
import Grid from '~/components/grid';
import Pill from '~/components/pill';
import Button from '~/components/button';
import UserProfileImage from '~/components/user-profile-image';
import UserReviewStars from '~/components/user-review-stars';
import FollowButton from '~/components/follow-button';

import QualitySVG from '~/assets/svg/icons/quality';
import FastShipperSVG from '~/assets/svg/icons/fast-shipper';
import MessageSVG from '~/assets/svg/icons/message';
import ShareSVG from '~/assets/svg/icons/share';
import AppStoreButtonSVG from '~/assets/svg/app-store-button';
import GooglePlayButtonSVG from '~/assets/svg/google-play-button';

import styles from '~/styles/pages/bookstore/[username]/styles.module.scss';
import { useGlobalState } from '~/state';
import { useRouter } from 'next/router';
import SnackSvgStar from '~/assets/svg/icons/snack-svg-star';
import ChevronRightSVG from '~/assets/svg/icons/chevron-right';
import { ActionType } from '~/state/types';
import BookReviews from '~/components/book-reviews';
import Spacer from '~/components/spacer';
import SalesCallouts from '../../../components/product/callouts/sales-callouts';
import COLORS from '~/lib/helpers/color-helper';
import MosaicSVG from '~/assets/svg/icons/mosaic';
import Callout from '../../../components/callout';
import HappyEmojiBgIcon from '../../../assets/svg/icons/happy-emoji-bg';
import classNames from 'classnames';
import SearchIcon from '~/assets/svg/icons/search';
import useDebounce from '~/lib/hooks/use-debounce';

const UserBookstore: NextPage<InferGetServerSidePropsType<typeof getStaticProps>> = (props) => {
  const router = useRouter();
  const { state, dispatch } = useGlobalState();
  const [searchOptions, setSearchOptions] = useState<TypesenseSearchProps>(props.searchOptions);
  const [followData, setFollowData] = useState<FollowData>(null);

  const searchQuery = useRouterSearchOptions({ searchOptions });

  const [bookstoreSearchQuery, setBookstoreSearchQuery] = useState('');
  const debouncedBookstoreSearchQuery = useDebounce(bookstoreSearchQuery, 500);

  const [inputIsEmpty, setInputIsEmpty] = useState(true);

  function handleInputChange(query) {
    setBookstoreSearchQuery(query);
    setInputIsEmpty(query.length === 0);
  }

  function handleClearSearch() {
    setBookstoreSearchQuery('');
    setInputIsEmpty(true);
  }

  const seller = Firebase<UserProfile>().fetchCollectionDocsByQuery(
    'users',
    [where('username', '==', router.query.username || props.params.username)],
    {
      initialData: [props.seller],
      enabled: Boolean(router.query?.username || props.params.username),
    }
  ).data[0];

  useEffect(() => {
    async function fetchFollow() {
      const data = await getFollowData(seller.id);
      setFollowData(data);
    }

    fetchFollow();
  }, [props.seller?.id, seller.id]);

  const searchResults = Typesense<Book>().searchWithFaceting({
    props: {
      ...searchQuery,
      params: {
        ...searchQuery.params,
        per_page: 100,
      },
    },
    reactQueryOptions: {
      initialData: props.searchResults,
      enabled: Boolean(searchQuery),
    },
  });

  const cleanSellerDoc = (seller: UserProfile & { docSnap?: any }) => {
    delete seller.email;
    delete seller.fcmToken;
    delete seller.docSnap;
  };

  cleanSellerDoc(seller);

  useEffect(() => {
    setSearchOptions({
      index: 'books',
      params: {
        q: bookstoreSearchQuery ? debouncedBookstoreSearchQuery : '*',
        query_by: mapQueryBy('books'),
        sort_by: mapSortBy('order_by_newest'),
        filter_by: `seller_id:${seller?.id}`,
      },
      options: {},
    });
  }, [seller?.id, debouncedBookstoreSearchQuery]);

  function hasBadges() {
    return isHighQualitySeller(seller) || isFastShipper(seller);
  }

  const handleShare = async () => {
    const title = 'Check it out!';
    const text = `${possessive(seller.name)} bookstore on PangoBooks`;
    const url = window.location.href;
    Share.link({
      title,
      text,
      url,
    });
  };

  function renderBookReviews() {
    if (!seller?.book_reviews) return null;

    function handleShowBookReviews() {
      dispatch({
        type: ActionType.ADD_MODAL_DATA,
        payload: {
          large: true,
          component: (
            <>
              <div className={styles['review-modal-title']}>{`${possessive(seller?.name)} Book Reviews`}</div>
              <Spacer />
              <Spacer />
              <BookReviews user_id={props.seller?.id} type='user' style='full' />
            </>
          ),
        },
      });
    }

    return (
      <div className={styles['action-buttons']} onClick={handleShowBookReviews}>
        <div className={styles['content']}>
          <div className={styles['content-icon']}>
            <SnackSvgStar />
          </div>
          <div className={styles['title']}>Book Reviews</div>
          <div className={styles['count']}>( {seller?.book_reviews} ) </div>
        </div>
        <div className={styles['icon']}>
          <ChevronRightSVG strokeColor={COLORS.grey} />
        </div>
      </div>
    );
  }

  function renderShelves() {
    return (
      <Link href={`/shelves/${router.query.username || props.params.username}`}>
        <div className={styles['action-buttons']}>
          <div className={styles['content']}>
            <div className={styles['content-icon']}>
              <MosaicSVG />
            </div>
            <div className={styles['title']}>Shelves</div>
          </div>
          <div className={styles['icon']}>
            <ChevronRightSVG strokeColor={COLORS.grey} />
          </div>
        </div>
      </Link>
    );
  }

  function renderShopPaused() {
    if (!seller?.on_hold) return null;

    return (
      <>
        <Callout
          icon={<HappyEmojiBgIcon fillColor={COLORS.primary} />}
          title={`This Shop Is Temporarily Paused`}
          message={`${seller?.name} has put their shop on vacation mode`}
        />
        <Spacer />
      </>
    );
  }

  function renderSellerHeader() {
    return (
      <header className={styles.header}>
        <div className={styles['header-container']}>
          <div className={styles['header-seller-info']}>
            <div className={styles['header-seller-info-container']}>
              <div className={styles['image-active-container']}>
                <div className={styles['header-seller-info-image']}>
                  <UserProfileImage user={seller} link={false} />
                </div>
                {lastActiveTime(seller) && (
                  <div className={styles['pill']}>
                    <Pill text={lastActiveTime(seller)} small shadow />
                  </div>
                )}
              </div>
              <div className={styles['header-seller-info-content']}>
                <h1>{seller.name}</h1>
                <h2>@{seller.username}</h2>
                <UserReviewStars user={seller} withCountText cssClass={styles['header-seller-info-reviews']} />
                {hasBadges() && (
                  <div className={styles['header-seller-badges']}>
                    {isHighQualitySeller(seller) && (
                      <Pill icon={<QualitySVG />} text='High Quality' className={styles['header-seller-badges-pill']} />
                    )}
                    {isFastShipper(seller) && (
                      <Pill
                        icon={<FastShipperSVG />}
                        text='Ultra Fast Shipper'
                        className={styles['header-seller-badges-pill']}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>

            {seller.bio && <p>{seller.bio}</p>}
          </div>
          <div className={styles['header-seller-actions']}>
            <div className={styles['header-seller-stats']}>
              <div className={styles['header-seller-stats-stat']}>
                <h3>Books Listed</h3>
                <span>{seller?.books_listed || 0}</span>
              </div>
              <div className={styles['header-seller-stats-stat']}>
                <h3>Following</h3>
                <span>{followData?.following || 0}</span>
              </div>
              <div className={styles['header-seller-stats-stat']}>
                <h3>Followers</h3>
                <span>{followData?.followers || 0}</span>
              </div>
            </div>

            <div className={styles['header-seller-buttons']}>
              <div className={styles['header-seller-buttons--follow']}>
                <FollowButton seller={seller} />
              </div>

              <Button
                style='secondary'
                icon={<MessageSVG fillColor={COLORS.primary} />}
                link={
                  seller?.id && {
                    href: Links.messages.general({
                      type: 'userId',
                      id: seller.id,
                    }),
                  }
                }
                disabled={seller?.id === state?.user?.data?.uid}
              />
              <Button style='secondary' icon={<ShareSVG />} onPress={handleShare} />
            </div>

            <div className={styles['header-seller-buttons--action']}>
              {renderBookReviews()}
              {renderShelves()}
            </div>
          </div>
        </div>
      </header>
    );
  }

  function renderSalesCallouts() {
    if (Boolean(seller.discounts) && seller.discounts.length) {
      return <SalesCallouts seller={seller} />;
    }
  }

  function renderAppCallout() {
    return (
      <div className={styles.app}>
        <h4>Get The App</h4>
        <p>Buy and sell anywhere</p>
        <div className={styles['app-buttons']}>
          <Link href={Links.appStore.apple} passHref>
            <AppStoreButtonSVG />
          </Link>
          <Link href={Links.appStore.google} passHref>
            <GooglePlayButtonSVG />
          </Link>
        </div>
      </div>
    );
  }

  function renderClearSearchInput() {
    if (debouncedBookstoreSearchQuery.length === 0) {
      return (
        <div className={styles['search-icon']}>
          <SearchIcon strokeColor={COLORS.lightGrey} />
        </div>
      );
    }

    return (
      <span
        onClick={handleClearSearch}
        className={classNames(styles['input-clear'], {
          [styles['input-clear--active']]: '',
        })}
      >
        x
      </span>
    );
  }

  function renderSearchInput() {
    return (
      <div className={styles['search-input-container']}>
        <div className={styles['input-container']}>
          <input
            type='search'
            onChange={(e) => handleInputChange(e.target.value)}
            className={classNames(styles.input)}
            value={bookstoreSearchQuery}
          />
          <span
            className={classNames(styles.placeholder, { [styles['placeholder-hidden']]: !inputIsEmpty })}
          >{`Search ${seller.name}'s listings`}</span>
          {renderClearSearchInput()}
        </div>
      </div>
    );
  }

  return (
    <>
      <PageMetaData
        title={`Browse books from ${seller.username}.`}
        description={`${possessive(seller.name)} shop on PangoBooks.`}
        twitterCard='summary'
      />
      {renderSellerHeader()}
      <section className={styles.container}>
        {renderSalesCallouts()}
        {renderShopPaused()}
        <Grid
          searchResults={searchResults as any}
          searchOptions={searchQuery}
          location='bookstore'
          searchInput={renderSearchInput}
        />
        {renderAppCallout()}
      </section>
    </>
  );
};

export async function getStaticPaths() {
  return { paths: [], fallback: 'blocking' };
}

export async function getStaticProps({ params }: GetStaticPropsContext) {
  let searchResults;

  const sellerQuery = await fetchCollectionDocsByQuery<UserProfile>({
    path: 'users',
    constraints: [where('username', '==', params.username)],
    parse: true,
  });

  const seller = sellerQuery && sellerQuery[0];

  const searchOptions: TypesenseSearchProps = {
    index: 'books',
    params: {
      q: '*',
      query_by: mapQueryBy('books'),
      sort_by: mapSortBy('order_by_newest'),
      filter_by: `seller_id:${seller?.id}`,
    },
    options: {},
  };

  if (seller) {
    searchResults = await searchWithFaceting<Book>(searchOptions);
  }

  if (!seller) {
    log404({ page: 'bookstore', slug: params?.username as string });
  }

  return {
    props: {
      params,
      seller,
      searchResults,
      searchOptions,
    },
    notFound: !seller,
    revalidate: 60,
  };
}

export default UserBookstore;
