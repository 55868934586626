import Callout from '@/components/callout';
import { SellerDiscount } from 'types';
import { formatMoney } from 'sdk';
import MoneyBackSVG from '@/assets/svg/icons/money-back';
import TruckSVG from '@/assets/svg/icons/truck';
import COLORS from '@/lib/helpers/color-helper';

type Props = {
  discounts: SellerDiscount[];
  small?: boolean;
  type: 'sales' | 'shipping';
};

function generateSalesMessage(discount: SellerDiscount) {
  if (discount.type === 'percent') {
    return discount?.conditions?.minValue
      ? `Get ${discount.additional ? 'an additional ' : ''}${discount.amount}% off when you spend ${formatMoney(
        discount.conditions.minValue
      )}`
      : `Get ${discount.amount}% off all orders`;
  }
  if (discount.type == 'value') {
    return discount?.conditions?.minValue
      ? `Get ${discount.additional ? 'an additional ' : ''}${formatMoney(
        discount.amount
      )} off when you spend ${formatMoney(discount.conditions.minValue)}`
      : `Get ${formatMoney(discount.amount)} off all orders`;
  }
  if (discount.type === 'shipping') {
    return discount?.conditions?.minValue
      ? `Free shipping when you spend ${formatMoney(discount.conditions.minValue)}`
      : 'Free shipping on all orders';
  }
  return '';
}

function generateSalesMessages(discounts: SellerDiscount[]) {
  return discounts.map((d) => generateSalesMessage(d)).join('\n');
}

export function SalesCallout({ discounts, small, type }: Props) {
  if (!discounts?.length) return null;

  const icon = type === 'sales' ? <MoneyBackSVG fillColor={COLORS.green} /> : <TruckSVG />;
  const title = type === 'sales' ? 'This shop is having a sale!' : 'Get free shipping!';
  const message = generateSalesMessages(discounts);

  return <Callout small={!!small} icon={icon} title={title} message={message} />;
}
