import { useState, useEffect, useCallback } from 'react';

import { useGlobalState } from '~/state';
import {
  addFollowedUserToFirestore,
  realtimeDocumentListener,
  removeFollowedUserFromFirestore,
} from '~/clients/firebase/client';
import { UserProfile } from 'types';

const useFollow = (seller: UserProfile) => {
  const { state } = useGlobalState();
  const {
    user: {
      data: { uid },
    },
  } = state;

  const [isFollowing, setIsFollowing] = useState<boolean>(null);
  const sellerId = seller?.id || seller?.objectID;

  useEffect(() => {
    if (!sellerId || !uid) {
      return;
    }

    let unsubscribe = realtimeDocumentListener(`users/${uid}/following`, sellerId, (doc) => {
      if (doc.exists()) {
        setIsFollowing(true);
      } else {
        setIsFollowing(false);
      }
    });

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [uid, seller]);

  const followUser = useCallback(async () => {
    // add the book to wishlist
    await addFollowedUserToFirestore(sellerId, uid);
  }, [seller, uid]);

  const unfollowUser = useCallback(async () => {
    // remove from wishlist
    await removeFollowedUserFromFirestore(sellerId, uid);
  }, [seller, uid]);

  return {
    followUser,
    unfollowUser,
    isFollowing,
  };
};

export default useFollow;
