import classNames from 'classnames';
import styles from '@/styles/components/typography/heading.module.scss';
import { spacing } from '@/lib/helpers/spacing';
import { ChildrenLayoutProps } from 'types';
import { HTMLAttributes } from 'react';
import { omit } from 'sdk';

type HeadingLevel = 'h1' | 'h2' | 'h3';

interface HeadingProps extends ChildrenLayoutProps, HTMLAttributes<HTMLHeadingElement> {
  children: React.ReactNode;
  as?: HeadingLevel;
  color?: 'primary' | 'copy' | 'copyDark' | 'white';
  size?: 'xtraTiny' | 'tiny' | 'small' | 'base' | 'medium' | 'large' | 'jumbo';
  align?: 'left' | 'center' | 'right';
}

export default function Heading({
  m = 0,
  mb = 0,
  ml = 0,
  mr = 0,
  mt = 0,
  p = 0,
  pb = 0,
  pl = 0,
  pr = 0,
  pt = 0,
  children,
  as = 'h1',
  color = 'copyDark',
  size = 'jumbo',
  align = 'left',
  ...props
}: HeadingProps) {
  const dynamicStyles = {
    ...(!!m && { margin: spacing.padding * m }),
    ...(!!mb && { marginBottom: spacing.padding * mb }),
    ...(!!ml && { marginLeft: spacing.padding * ml }),
    ...(!!mr && { marginRight: spacing.padding * mr }),
    ...(!!mt && { marginTop: spacing.padding * mt }),
    ...(!!p && { padding: spacing.padding * p }),
    ...(!!pb && { paddingBottom: spacing.padding * pb }),
    ...(!!pl && { paddingLeft: spacing.padding * pl }),
    ...(!!pr && { paddingRight: spacing.padding * pr }),
    ...(!!pt && { paddingTop: spacing.padding * pt }),
    ...(!!align && { textAlign: align })
  };


  const HeadingTag = as;
  return (
    <HeadingTag
      style={dynamicStyles}
      className={classNames(
        styles.headingContainer,
        styles[color],
        styles[size],
        props.className,
      )}
      // Removed props.className from spreading to avoid duplication
      {...omit(props, ['className'])}
    >
      {children}
    </HeadingTag>
  );
}

