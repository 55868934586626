import COLORS from "~/lib/helpers/color-helper";

interface Props {
  fillColor?: string;
  starColor?: string;
  width?: string;
  height?: string;
}

export default function SnackSvgStar({ fillColor = COLORS.primary, starColor = 'white', width = '48', height = '48' }: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width={width} height={height} rx="8" fill={fillColor} />
      <path d="M23.2496 15.0664C23.5566 14.4445 24.4434 14.4445 24.7504 15.0664L27.2322 20.0942L32.7833 20.9056C33.4694 21.0059 33.7429 21.8493 33.2461 22.3331L29.23 26.2448L30.1777 31.7699C30.2949 32.4535 29.5773 32.9748 28.9634 32.652L24 30.0418L19.0366 32.652C18.4227 32.9748 17.7051 32.4535 17.8223 31.7699L18.77 26.2448L14.7538 22.3331C14.2571 21.8493 14.5306 21.0059 15.2167 20.9056L20.7678 20.0942L23.2496 15.0664Z" fill={starColor} />
      <path opacity="0.4" d="M5 13V9C5 6.79086 6.79086 5 9 5H13" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
      <path opacity="0.4" d="M43 35V39C43 41.2091 41.2091 43 39 43H35" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  );
}
