import COLORS from '../../../lib/helpers/color-helper';

interface Props {
  fillColor?: string;
}
export default function HappyEmojiBgIcon({ fillColor = COLORS.copyDark }: Props) {
  return (
    <svg viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle opacity="0.1" cx="28" cy="28" r="28" fill={fillColor} />
      <g transform="translate(-5, -5) scale(1.75) translate(9, 9)">
        <path d="M9.97493 1.66663C5.37493 1.66663 1.6416 5.39996 1.6416 9.99996C1.6416 14.6 5.37493 18.3333 9.97493 18.3333C14.5749 18.3333 18.3083 14.6 18.3083 9.99996C18.3083 5.39996 14.5833 1.66663 9.97493 1.66663ZM14.4999 13.6916C13.4749 15.1666 11.7916 16.0416 9.99993 16.0416C8.20827 16.0416 6.52493 15.1666 5.49993 13.6916C5.29993 13.4083 5.37493 13.0166 5.65827 12.825C5.9416 12.625 6.33327 12.7 6.52493 12.9833C7.3166 14.1166 8.6166 14.8 9.99993 14.8C11.3833 14.8 12.6833 14.125 13.4749 12.9833C13.6749 12.7 14.0583 12.6333 14.3416 12.825C14.6333 13.0166 14.6999 13.4083 14.4999 13.6916Z" fill={fillColor} />
      </g>
    </svg>
  );
}